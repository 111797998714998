import "ag-grid-community/dist/styles/ag-grid.min.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";

import { _ } from "vue-underscore";

export default {
  name: "blogPostList",
  data() {
    return {
      showModal: false,
      blogPostList: [],
      selected: "",
      searchText: "",
      companyList: [],
      company_id: "",
      showLoader: false,
      selectedId: 0,
      selectedRows: [],
      sortColumn: "meta_title",
      sortDirection: "asc",
      webUrl: process.env.VUE_APP_BLOG_URL,
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { PaginationComponent },
  computed: {
    selectAllList() {
      if (this.blogPostList.length > 0) {
        return (
          _.where(this.blogPostList, {
            isSelected: true,
          }).length === this.blogPostList.length
        );
      }
      return false;
    },
    hasBlogPostShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogPostAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogPostDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogPostUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasBlogPostHindShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "BlogPost Show|Hide" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "BlogPost Show|Hide" })
                .length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, {
                    name: "BlogPost Show|Hide",
                  }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?company=" +
        this.company_id +
        "&page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },

    updateBlogPstStatus(blogPst, event) {
      let _vm = this;
      let _msg =
        blogPst.isDisplay == true
          ? "Are you sure you want to Deactivate this Blog Post?"
          : "Are you sure you want to Activate this Blog Post?";
      let _isDisplay = blogPst.isDisplay == true ? 1 : 0;
      // Display confirmation dialog
      this.$dialog
        .confirm(_msg)
        .then(function () {
          // Send a POST request to update the status
          _vm.axios
            .get("/blog/posts/" + blogPst.id + "/edit")
            .then(function () {
              // Refresh the data after successful update
              _vm.getAll();
            })
            .catch(function () {
              // Handle error and revert checkbox state
              document.getElementById("blogPst-" + blogPst.id).checked =
                blogPst.isDisplay;
            });
        })
        .catch(function () {
          // User canceled the confirmation dialog, revert checkbox state
          document.getElementById("blogPst-" + blogPst.id).checked =
            blogPst.isDisplay;
        });
    },

    checkUncheckedList(event) {
      this.blogPostList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    search() {
      this.getAll();
    },
    deleteRecord(id) {
      // This function deletes a record from the blog post list
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.blogPostList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      this.modal7;
      _vm.axios
        .delete("/blog/posts/0", {
          data: {
            ids: ids,
          },
        })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    getAll() {
      const _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Perform a GET request to retrieve blog posts
      this.axios
        .get("/blog/posts" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.blogPostList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    CompanySlugData() {
      let _vm = this;
      _vm.getAll();
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.company_id = _vm.companyList[0].id;
          _vm.getAll();
        })
        .catch(function () {});
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Select all rows
          this.selectedRows = this.blogPostList.map(
            (item) => "checkbox" + item.id
          );
          this.blogPostList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Deselect all rows
          this.selectedRows = [];
          this.blogPostList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the selected row to the list
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the deselected row from the list
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getCompany();
  },
};
